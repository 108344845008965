$color--black: black;
$color--white: white;

$color--violet: #2700ef;
$color--blue: #00e;
$color--green: #00e00e;

* {
  box-sizing: border-box; }

html, body {
  padding: 0;
  margin: 0;

  font-family: serif;

  color: $color--black;
  background-color: $color--white;

  @media (prefers-color-scheme: dark) {
    color: darken($color--white, 20);
    background-color: lighten($color--black, 5); } }

body {
  margin: 20px; }

h1 {
  margin-top: 0;
  font-size: 1.25rem; }

a {
  color: $color--violet;

  @media (prefers-color-scheme: dark) {
    color: lighten($color--violet, 15); } }

// main
//   padding: 5rem 0
//   flex: 1
//   display: flex
//   flex-direction: column
//   justify-content: center
//   align-items: center
//
// footer
//   width: 100%
//   height: 100px
//   border-top: 1px solid #eaeaea
//   display: flex
//   justify-content: center
//   align-items: center
//
// footer span, footer a
//   margin-left: 0.5rem
//   margin-right: 0.5rem
//
// a
//   color: inherit
//   text-decoration: none
//
// code
//   background: #fafafa
//   border-radius: 5px
//   padding: 0.75rem
//   font-size: 1.1rem
//   font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace
